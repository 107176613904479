import React, { useState, useEffect } from "react";
import '../styles/AboutDynamicText.css';  // CSS import

export default function AboutMe() {
  const [currentText, setCurrentText] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);
  const [loopIndex, setLoopIndex] = useState(0);

  const words = [
    "Hello",      // English
    "Bonjour",    // French
    "Hola",       // Spanish
    "Hallo",      // German
    "Ciao",       // Italian
    "سلام",       // Farsi (Persian)
    "שלום",       // Hebrew
    "你好",       // Mandarin (Chinese)
    "नमस्ते",     // Hindi
    "こんにちは", // Japanese
    "안녕하세요", // Korean
    "مرحبا",     // Arabic
    "Здравствуйте" // Russian
  ];

  const typingSpeed = 150;
  const deletingSpeed = 100;
  const pauseDelay = 2000; // Time to pause after the text is fully typed or deleted

  useEffect(() => {
    const handleTyping = () => {
      const word = words[loopIndex % words.length];

      if (!isDeleting) {
        setCurrentText((prev) => word.substring(0, prev.length + 1));

        if (currentText === word) {
          setTimeout(() => setIsDeleting(true), pauseDelay);
        }
      } else {
        setCurrentText((prev) => word.substring(0, prev.length - 1));

        if (currentText === "") {
          setIsDeleting(false);
          setLoopIndex((prev) => prev + 1);
        }
      }
    };

    const timer = setTimeout(
      handleTyping,
      isDeleting ? deletingSpeed : typingSpeed
    );

    return () => clearTimeout(timer);
  }, [currentText, isDeleting, loopIndex]);

  return (
    <h1 className="dynamic-text">
    {currentText} <span role="img" aria-label="wave">👋</span>
  </h1>
  );
}
