import React, { useState, useEffect } from 'react';
import HormannLogoModel from './HormannLogoModel'; // Hormann 
import HondaLogoModel from './HondaLogoModel'; // Honda
import GeorgianLogoModel from './GeorgianLogoModel'; // Georgian
import FFLogoModel from './FFLogoModel'; // Further Faster
import YRLogoModel from './YRLogoModel'; // York Region
import LULogoModel from './LULogoModel'; // York Region
import AboutMe from "./AboutMe"; // Import the AboutMe component

import '../styles/home.css';  // CSS import
import '../styles/AboutDynamicText.css';  // CSS import

// Import images from src/Assets/images
import logoIcon from '../Assets/images/Logo.png';
import resumePDF from '../Assets/images/Resume.pdf';
import omidProfile from '../Assets/images/omid3.png';
import hondaLogo from '../Assets/images/honda.png';
import georgianLogo from '../Assets/images/georgian.png';
import hormannLogo from '../Assets/images/hormann.png';
import yrLogo from '../Assets/images/yr.png';
import ffLogo from '../Assets/images/ff.png';
import nodeLogo from '../Assets/images/node.png';
import mongoLogo from '../Assets/images/mongo.png';
import hbsLogo from '../Assets/images/hbs.png';
import aspLogo from '../Assets/images/asp.png';
import ssmsLogo from '../Assets/images/ssms.png';
import nugetLogo from '../Assets/images/nuget.png';
import pythonLogo from '../Assets/images/python.png';
import AzureDevOpsLogo from '../Assets/images/AzureDevOps.png';
import AIHackLogo from '../Assets/images/AIHack.png';
import CyberSecurityLogo from '../Assets/images/Cyber-Security-Logo.png';
import RiseLogo from '../Assets/images/Rise.jpg';
import HelpDeskLogo from '../Assets/images/HelpDesk.png';
import ReactLogo from '../Assets/images/React.webp';
import JsLogo from '../Assets/images/Js.webp';
import HtmlLogo from '../Assets/images/Html.webp';
import CplusplusLogo from '../Assets/images/C++.png';
import TWLogo from '../Assets/images/tw.png';
import AspNetLogo from '../Assets/images/aspnet_logo.webp';
import NodeIconLogo from '../Assets/images/NodeIcon.png';
import SAPLogo from '../Assets/images/SAP.png';
import vbaLogo from '../Assets/images/vba.png';
import abapLogo from '../Assets/images/sapabap.png';
import nasaSpaceApps from '../Assets/images/nasaSpaceApps.png';

export default function Home() {

  const currentYear = new Date().getFullYear();

  const [isNight, setIsNight] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleThemeToggle = () => {
    const themeToggle = document.getElementById('theme-toggle');
  
    // Fade out the current icon
    themeToggle.style.transition = 'opacity 0.3s ease';
    themeToggle.style.opacity = 0;
  
    setTimeout(() => {
      setIsNight((prev) => !prev); // Toggle theme state
  
      // Update the icon based on the new state
      themeToggle.innerHTML = isNight ? '&#9788;' : '&#9789;'; // Sun or Moon
  
      // Fade in the new icon
      themeToggle.style.opacity = 1;
    }, 300); // Duration matches the CSS transition
  };

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
    document.querySelector('.hamburger').classList.toggle('open');
  };

  useEffect(() => {
    const body = document.body;

    if (isNight) {
      body.classList.add('night');
    } else {
      body.classList.remove('night');
    }
  }, [isNight]);

  useEffect(() => {
    const themeToggle = document.getElementById('theme-toggle');
    const body = document.body;

    if (themeToggle) {
      themeToggle.addEventListener('click', () => {
        // Smooth fade out of the current icon
        themeToggle.style.transition = 'opacity 0.3s ease';
        themeToggle.style.opacity = 0;

        setTimeout(() => {
          // Swap the icon after fade-out is complete
          if (body.classList.contains('night')) {
            themeToggle.innerHTML = '&#9789;'; // Moon icon
          } else {
            themeToggle.innerHTML = '&#9788;'; // Sun icon
          }

          // Smooth fade in of the new icon
          themeToggle.style.opacity = 1;
        }, 300); // Duration should match the CSS transition
      });
    }

    // Cleanup event listener
    return () => {
      if (themeToggle) {
        themeToggle.removeEventListener('click', handleThemeToggle);
      }
    };
  }, [isNight]);

  useEffect(() => {
    // Check for system dark mode preference
    const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
    if (prefersDarkMode) {
      setIsNight(true);
    }

    // Dynamic Text
    const titles = ["Software Engineer", "Systems Analyst", "Data Analyst",  "Project Manager", "Test Engineer", "3D Designer"];
    let currentIndex = 0;

    function changeTitle() {
      const dynamicText = document.getElementById("dynamic-text");
      if (dynamicText) {
        dynamicText.style.opacity = 0; // Start fade out
        setTimeout(() => {
          currentIndex = (currentIndex + 1) % titles.length;
          dynamicText.textContent = titles[currentIndex];
          dynamicText.style.opacity = 1; // Fade back in
        }, 500); // Duration should match the CSS transition
      }
    }

    const titleInterval = setInterval(changeTitle, 2500); // Change title every 2.5 seconds

    // Hero h1 underline on website load
    window.addEventListener('load', function () {
      const heroTitle = document.querySelector('#hero h1');
      if (heroTitle) {
        setTimeout(() => {
          heroTitle.classList.add('animate-underline');
        }, 100); // Adjust the delay as needed
      }
    });

    // Setup Carousel
    const carousel = document.querySelector('.logo-carousel');
    const inner = document.querySelector('.logo-carousel .inner');
    const logos = document.querySelectorAll('.logo-carousel img');
    const logoWidth = 275; // Adjust this according to your actual logo width
    const logoSpacing = 1.3; // Adjust this value to increase spacing between logos
    const totalWidth = logos.length * logoWidth * logoSpacing;

    let speed = 1 / 3; // Pixels to move per frame. Adjust this to control speed.

    function setupCarousel() {
      if (!inner || logos.length === 0) return;

      // Remove any cloned logos
      inner.querySelectorAll('img:not([data-original])').forEach(img => img.remove());

      // Mark original logos
      logos.forEach(logo => logo.setAttribute('data-original', ''));

      // Clone logos and append to create a continuous effect
      logos.forEach(logo => {
        const clone = logo.cloneNode(true);
        inner.appendChild(clone);
      });

      // Set initial positions
      Array.from(inner.children).forEach((logo, index) => {
        logo.style.position = 'absolute';
        logo.style.left = `${index * logoWidth * logoSpacing}px`;
      });
    }

    function moveLogos() {
      if (!inner) return;

      Array.from(inner.children).forEach(logo => {
        let currentLeft = parseFloat(logo.style.left);
        currentLeft -= speed;

        if (currentLeft <= -logoWidth) {
          currentLeft += totalWidth;
        }

        logo.style.left = `${currentLeft}px`;
      });

      requestAnimationFrame(moveLogos);
    }

    setupCarousel();
    moveLogos();
    window.addEventListener('resize', setupCarousel);

    // Cleanup event listeners and intervals
    return () => {
      clearInterval(titleInterval);
      window.removeEventListener('resize', setupCarousel);
    };

  }, []);

  return (
    <>
      <meta charSet="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <title>Omid's Portfolio</title>
      <link rel="stylesheet" href="./app.css" />
      <link
        rel="stylesheet"
        href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css"
      />
      <link rel="mask-icon" href="mask-icon.svg" color="#000000" />
      <link rel="icon" href={logoIcon} type="image/x-icon" />
      <meta
        name="title"
        property="og:title"
        content="Omid Latifi's Portfolio Site"
      />
      <meta name="type" property="og:type" content="Website" />
      <meta
        name="image"
        property="og:image"
        content="Omid Latifi's Portfolio Site"
      />
      <meta name="url" property="og:url" content="https://omidlatifi.com/" />
      <meta
        name="description"
        property="og:description"
        content="Connect with Omid Latifi"
      />
      <div id="container">
      <header>
  <nav>
    <button className="hamburger" onClick={handleMenuToggle}>
      <span></span>
      <span></span>
      <span></span>
    </button>
    <ul className={isMenuOpen ? 'open' : ''}>
    <li><a href="#about" onClick={handleMenuToggle}>About</a></li>
              <li><a href="#experience" onClick={handleMenuToggle}>Work</a></li>
              <li><a href="#portfolio" onClick={handleMenuToggle}>Portfolio</a></li>
              <li><a href="#certifications" onClick={handleMenuToggle}>Certificates</a></li>
              <li><a href="#education" onClick={handleMenuToggle}>Education</a></li>
              <li><a href={resumePDF} onClick={handleMenuToggle}>Resume</a></li>
              <li><a href="#contact" onClick={handleMenuToggle}>Contact</a></li>
    </ul>
    <button
      className="theme-toggle"
      id="theme-toggle"
      onClick={handleThemeToggle}
    >
      {isNight ? '☾' : '☼'}
    </button>
  </nav>
</header>
        <section id="hero">
          <h1>OMID LATIFI</h1>
          <p id="dynamic-text">Software Engineer</p>
          <div id="social-links">
            <div id="social-icons">
              <a
                href="https://www.linkedin.com/in/omid-latifi-2bb380215/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-linkedin" />
              </a>
              <a href="https://github.com/omidlatifi123" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-github" />
              </a>
              <a href="mailto:omidlatifi2002@gmail.com">
                <i className="fas fa-envelope" />
              </a>
            </div>
            <a href={resumePDF}>
              <button>Download Resume</button>
            </a>
          </div>
        </section>

        <section id="about">
  <h2>About Me</h2>
  <div className="bg-shapes"></div>
  <div className="about-content">
    <div className="profile-pic-container">
      <img
        src={omidProfile}
        alt="Omid's Profile Picture"
        className="profile-pic"
      />
    </div>
    <div className="about-text">
    <p>
    <div> <p className="dynamic-text"><AboutMe /></p> I'm <span className="highlight">Omid Latifi</span></div>
     a dedicated software engineer
    with a strong focus on developing efficient, scalable solutions.
    My expertise spans a broad spectrum of programming languages and frameworks,
    enabling me to approach and solve complex challenges with confidence and precision.
  </p>
      <p>
  Throughout my career as a software developer, I have successfully led a variety of projects, 
  including the development of organizational automation solutions, cutting-edge mobile applications, 
  and comprehensive full-stack systems. I thrive in collaborative environments, 
  where I contribute to innovative solutions and propel projects forward. 
  My expertise spans:
</p>
<div className="interests">
  <div className="interest-item">
    <i className="fas fa-cogs" /> {/* Automation Icon */}
    <p>Automation</p>
  </div>
  <div className="interest-item">
    <i className="fas fa-chart-line" /> {/* Optimization Icon */}
    <p>Optimization</p>
  </div>
  <div className="interest-item">
    <i className="fas fa-project-diagram" /> {/* System Design Icon */}
    <p>System Design</p>
  </div>
</div>
      <p>Let's connect and create something impactful!</p>
      <div className="social-links">
        <a
          href="https://www.linkedin.com/in/omid-latifi-2bb380215/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fab fa-linkedin" />
        </a>
        <a href="https://github.com/omidlatifi123" target="_blank" rel="noopener noreferrer">
          <i className="fab fa-github" />
        </a>
        <a href="mailto:omidlatifi2002@gmail.com">
          <i className="fas fa-envelope" />
        </a>
      </div>
    </div>
  </div>
</section>

        <h2>Work Experience</h2>
        <div className="logo-carousel">
          <div className="fade-left" />
          <div className="inner">
            <img src={hondaLogo} alt="Honda Logo" />
            <img src={georgianLogo} alt="Georgian Logo" />
            <img src={hormannLogo} alt="Hormann Logo" />
            <img src={yrLogo} alt="YR Logo" />
            <img src={ffLogo} alt="FF Logo" />
            <img src={hondaLogo} alt="Honda Logo" />
            <img src={georgianLogo} alt="Georgian Logo" />
            <img src={hormannLogo} alt="Hormann Logo" />
            <img src={yrLogo} alt="YR Logo" />
            <img src={ffLogo} alt="FF Logo" />
          </div>
          <div className="fade-right" />
        </div>

        <section id="experience">

        <div className="experience-item-hormann" id='Hormann'>
        <div className="logo-container">
          <HormannLogoModel />
        </div>
        <div className="experience-content">
          <div className="experience-header">
            <div className="header-left">
              <h3>Hörmann</h3>
              <p className="job-title">Software Developer</p>
            </div>
            <div className="header-right">
              <p className="job-duration">May 2024 - Present</p>
              <p className="job-location">Barrie, Ontario, Canada</p>
            </div>
          </div>
          <div className="experience-body">
            <div className="experience-details">
              <h4>Responsibilities</h4>
              <ul className="job-responsibilities">
                <li>Implemented Python applications to automate vendor email communications and dataset updates, resulting in a 99% reduction in time-to-update.</li>
                <li>Engineered a VBA-based optimization system to streamline barrel cutting processes, resulting in substantial reductions in material waste and labor costs.</li>
                <li>Collaborated with cross-functional teams, including Sales, Scheduling, Production, and Purchasing, to develop a suite of automation tools that enhanced workflow efficiency and generated considerable annual savings.</li>
                <li>Created comprehensive Compatibility Chart documents, optimizing product alignment and engineering processes in partnership with the engineering team.</li>
              </ul>
            </div>
            <div className="key-achievements">
              <h4>Highlight</h4>
              <ul>
                <li>Drastically reduced vendor communication time by implementing automated solutions.</li>
                <li>Achieved significant cost savings and process efficiencies by optimizing barrel cutting operations.</li>
                <li>Developed document generation tools in collaboration with the Engineering department, automating complex tasks and driving substantial annual savings.</li>
                <li>Streamlined the COGS calculation process through automation, leading to enhanced accuracy and reduced manual effort.</li>
                <li>Generated substantial annual savings and reduced labor hours across various departments through the implementation of advanced automation solutions.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="experience-item-gr" id='GR'>
      <div className="logo-container">
          <GeorgianLogoModel />
        </div>
        <div className="experience-content">
          <div className="experience-header">
            <div className="header-left">
              <h3>Georgian Research and Innovation</h3>
              <p className="job-title">Research Associate in Software</p>
            </div>
            <div className="header-right">
              <p className="job-duration">May 2024 - Present</p>
              <p className="job-location">Barrie, Ontario, Canada</p>
            </div>
          </div>
          <div className="experience-body">
            <div className="experience-details">
              <h4>Responsibilities</h4>
              <ul className="job-responsibilities">
                <li>Full-time Research Associate specializing in software development and data analytics, leveraging cutting-edge technologies to drive innovation and optimize processes across various projects.</li>
                <li>Collaborated closely with cross-functional teams to design and implement software systems that address complex challenges, ensuring alignment with project goals and industry standards.</li>
                <li>Led and contributed to high-impact research and development projects for organizations, including Honda, Hörmann, and XR-AI, focusing on creating scalable software solutions and advanced data analysis methodologies.</li>    
              </ul>
            </div>
            <div className="key-achievements">
              <h4>Highlight</h4>
              <ul>
                <li>Played a pivotal role in the successful delivery of multiple high-impact projects in software development and data analytics, driving innovation and delivering measurable results for clients.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

        <div className="experience-item-honda" id='Honda'>
          <div className="logo-container">
            <HondaLogoModel />
          </div>
          <div className="experience-content">
            <div className="experience-header">
              <div className="header-left">
                <h3>Honda Canada</h3>
                <p className="job-title">Data Analyst</p>
              </div>
              <div className="header-right">
                <p className="job-duration">June 2024 - December 2024</p>
                <p className="job-location">Alliston, Ontario, Canada</p>
              </div>
            </div>
            <div className="experience-body">
              <div className="experience-details">
                <h4>Responsibilities</h4>
                <ul className="job-responsibilities">
                  <li>Conducted extensive data analysis and categorized large datasets, identifying key factors and trends.</li>
                  <li>Automated data collection and cleaning processes using VBA, Python, and SQL, significantly reducing processing time.</li>
                  <li>Regularly presented data-driven insights and recommendations to senior management, supporting strategic decision-making and operational enhancements.</li>                </ul>
              </div>
              <div className="key-achievements">
                <h4>Highlight</h4>
                <ul>
                  <li>Significantly reduced data processing time through automation.</li>
                  <li>Enhanced strategic decision-making by delivering critical insights to senior management.</li>
                  <li>Analyzed a set of materials to recommend cost-effective substitutes, reducing expenses.</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

      <div className="experience-item-ff" id='FF'>
      <div className="logo-container">
          <FFLogoModel />
        </div>
        <div className="experience-content">
          <div className="experience-header">
            <div className="header-left">
              <h3>Further Faster</h3>
              <p className="job-title">Project Manager</p>
            </div>
            <div className="header-right">
              <p className="job-duration">January 2024 - April 2024</p>
              <p className="job-location">Barrie, Ontario, Canada</p>
            </div>
          </div>
          <div className="experience-body">
            <div className="experience-details">
              <h4>Responsibilities</h4>
              <ul className="job-responsibilities">
                <li>Led a team of four developers, coordinating efforts to design, develop, and deploy a robust platform that fosters a community-driven approach to software debugging.</li>
                <li>Collaborated with industry mentors to integrate product and business development insights, resulting in a platform that not only meets technical requirements but also aligns with market needs and business goals.</li>
                <li>Managed the project lifecycle from inception to deployment, including planning, resource allocation, timeline management, and risk mitigation, ensuring the successful delivery of a high-quality platform.</li>
                <li>Facilitated regular team meetings, progress updates, and mentor consultations, maintaining clear communication channels and fostering a collaborative work environment.</li>
              </ul>
            </div>
            <div className="key-achievements">
              <h4>Highlight</h4>
              <ul>
                <li>Successfully managed the development and launch of GeorgianCodeForum.com, a community-driven platform that supports software debugging and developer collaboration.</li>
                <li>Demonstrated strong leadership and project management skills, effectively guiding a team of four developers to deliver a functional and user-friendly platform.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="experience-item-yr" id='YR'>
      <div className="logo-container">
          <YRLogoModel />
        </div>
        <div className="experience-content">
          <div className="experience-header">
            <div className="header-left">
              <h3>York Region, Municipality of York</h3>
              <p className="job-title">Software Developer</p>
            </div>
            <div className="header-right">
              <p className="job-duration">April 2023 - September 2023</p>
              <p className="job-location">Newmarket, Ontario, Canada</p>
            </div>
          </div>
          <div className="experience-body">
            <div className="experience-details">
              <h4>Responsibilities</h4>
              <ul className="job-responsibilities">
                <li>Developed Data Analysis PowerApp for paramedic data requests, achieving 55% reduction in response times.</li>
                <li>implemented a Power BI KPI dashboard for enhanced data visualization, leading to a 70% decrease in data request turnaround time, enabling faster decision-making.</li>
                <li>Designed a paramedic data pipeline integration, ensuring up to 20% higher data accuracy from emergency vehicles.</li>
              </ul>
            </div>
            <div className="key-achievements">
              <h4>Highlight</h4>
              <ul>
                <li>Successfully reduced paramedic data response times by 55% through the development of an innovative Data Analysis PowerApp.</li>
                <li>Decreased data request turnaround time by 70% with the creation of a dynamic Power BI KPI dashboard.</li>
                <li>Improved emergency response time accuracy by up to 20% through the design and implementation of a data pipeline integration.</li>
                <li>Collaborated with the IT team to test and validate the integration of Oracle PeopleSoft ERP's Payroll Processing Module with the HR module.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
        <section id="portfolio">
          <h2>Portfolio</h2>
          <div className="portfolio-grid">
            <div className="portfolio-item">
              <a href="https://georgiancodeforum.com/" className="portfolio-link">
                <h3>Georgian Code Forum</h3>
                <p>Community Debugging Forum</p>
                <div className="tech-stack">
                  <h4>Tech Stack:</h4>
                  <div className="tech-icons">
                    <img src={nodeLogo} alt="Node.js" />
                    <img src={mongoLogo} alt="MongoDB" />
                    <img src={hbsLogo} alt="Handlebars" />
                  </div>
                </div>
                <span className="github-link">
                  <i className="fab fa-github" /> View on GitHub
                </span>
              </a>
            </div>
            <div className="portfolio-item">
              <a
                href="https://github.com/yourusername/latifi-chapters"
                className="portfolio-link"
              >
                <h3>Latifi Chapters</h3>
                <p>Online BookStore</p>
                <div className="tech-stack">
                  <h4>Tech Stack:</h4>
                  <div className="tech-icons">
                    <img src={aspLogo} alt="ASP.net" />
                    <img
                      src={ssmsLogo}
                      alt="SQL Server Management Studio"
                    />
                    <img
                      src={nugetLogo}
                      alt="Nuget Package Manager"
                    />
                  </div>
                </div>
                <span className="github-link">
                  <i className="fab fa-github" /> View on GitHub
                </span>
              </a>
            </div>
            <div className="portfolio-item">
              <a href="https://drivemind.vercel.app/" className="portfolio-link">
                <h3>DriveMind</h3>
                <p>Machine-Learning Traffic Simulator</p>
                <div className="tech-stack">
                  <h4>Tech Stack:</h4>
                  <div className="tech-icons">
                    <img src={pythonLogo} alt="Python" />
                  </div>
                </div>
                <span className="github-link">
                  <i className="fab fa-github" /> View on GitHub
                </span>
              </a>
            </div>
          </div>
        </section>
        <section id="skills">
          <h2>Skills</h2>
          <div className="skills-content">
            <div className="skill-category">
              <h3>
                <i className="fas fa-code" /> Programming Languages
              </h3>
              <ul>
                <li>Python</li>
                <li>JavaScript</li>
                <li>C++</li>
                <li>Java</li>
                <li>Kotlin</li>
                <li>SQL</li>
                <li>Microsoft VBA</li>
                <li>SAP ABAP</li>
              </ul>
            </div>
            <div className="skill-category">
              <h3>
                <i className="fas fa-laptop-code" /> Frameworks &amp; Libraries
              </h3>
              <ul>
                <li>React</li>
                <li>Node.js</li>
                <li>Express.js</li>
                <li>Django</li>
                <li>Flask</li>
                <li>Bootstrap</li>
              </ul>
            </div>
            <div className="skill-category">
              <h3>
                <i className="fas fa-tools" /> Tools &amp; Software
              </h3>
              <ul>
                <li>Git</li>
                <li>Docker</li>
                <li>Visual Studio Code</li>
                <li>PyCharm</li>
                <li>Android Studio</li>
                <li>JIRA</li>
                <li>PeopleSoft</li>
                <li>Excel</li>
                <li>SAP</li>
              </ul>
            </div>
            <div className="skill-category">
              <h3>
                <i className="fas fa-database" /> Databases
              </h3>
              <ul>
                <li>MySQL</li>
                <li>PostgreSQL</li>
                <li>MongoDB</li>
                <li>SQLite</li>
                <li>Firebase</li>
                <li>4HANA</li>
              </ul>
            </div>
          </div>
        </section>
       
        <section id="certifications">
      <h2>Certifications</h2>
      <div className="certifications-container">
      <div className="certification-item">
          <div className="certification-logo">
            <img src={SAPLogo} alt="SAP Logo" />
          </div>
          <div className="certification-details">
            <h3>Planning Basics in SAP</h3>
            <p>Linkedin Learning</p>
            <p className="certification-date">Issued: August 2024</p>
            <a href="https://www.linkedin.com/learning/certificates/a09a39ed4d89a4c264eabdfd0240f0e25de31a4ae2057b03344aa62eb12a0647?u=2175986" className="certification-link">View Certificate</a>
          </div>
        </div>
      <div className="certification-item">
          <div className="certification-logo">
            <img src={SAPLogo} alt="SAP Logo" />
          </div>
          <div className="certification-details">
            <h3>SAP ERP Essential Training</h3>
            <p>Linkedin Learning</p>
            <p className="certification-date">Issued: August 2024</p>
            <a href="https://www.linkedin.com/learning/certificates/7e3e49746d975728799ee8cfa04596d4a90de7f73369b99f57b27809f56e69e1?trk=share_certificate" className="certification-link">View Certificate</a>
          </div>
        </div>
        <div className="certification-item">
          <div className="certification-logo">
            <img src={SAPLogo} alt="SAP Logo" />
          </div>
          <div className="certification-details">
            <h3>SAP S/4HANA: Beyond the Basics</h3>
            <p>Linkedin Learning</p>
            <p className="certification-date">Issued: August 2024</p>
            <a href="https://www.linkedin.com/learning/certificates/a232517db2be308e417d5855075ec97e15e5a6b193ed4ada77f2ec612623982f?trk=share_certificate" className="certification-link">View Certificate</a>
          </div>
        </div>
        <div className="certification-item">
          <div className="certification-logo">
            <img src={HelpDeskLogo} alt="RISE & Innov8 Logo" />
          </div>
          <div className="certification-details">
            <h3>IT Help Desk for Beginners</h3>
            <p>Linkedin Learning</p>
            <p className="certification-date">Issued: August 2024</p>
            <a href="https://www.linkedin.com/learning/certificates/5c007ca066123f028579d0c628c04e43b2f1584e3a2c2e8be74cb3b2fca62d0b?trk=share_certificate" className="certification-link">View Certificate</a>
          </div>
        </div>
        
        
        <div className="certification-item">
          <div className="certification-logo">
            <img src={AzureDevOpsLogo} alt="Scrum.org Logo" />
          </div>
          <div className="certification-details">
            <h3>Azure DevOps for Beginners</h3>
            <p>Linkedin Learning</p>
            <p className="certification-date">Issued: December 2023</p>
            <a href="https://www.linkedin.com/learning/certificates/5544a0de61114dbc6a30fe63f91e36d53c693fe49cde681b79113a2db9f840c3?trk=share_certificate" className="certification-link">View Certificate</a>
          </div>
        </div>
        <div className="certification-item">
          <div className="certification-logo">
            <img src={CyberSecurityLogo} alt="Oracle Logo" />
          </div>
          <div className="certification-details">
            <h3>Cybersecurity Foundations</h3>
            <p>Project Management Institue, Linkedin Learning</p>
            <p className="certification-date">Issued: March 2023</p>
            <a href="https://www.linkedin.com/learning/certificates/6d5de09f5eb08b74d0f7bda1c4fc22de01791ca927a0c5319812ec546acc896f?trk=share_certificate" className="certification-link">View Certificate</a>
          </div>
        </div>
      </div>
    </section>

    <section id="certifications">
      <h2>Hackathons & Competitions</h2>
      <div className="certifications-container">

      <div className="certification-item">
          <div className="certification-logo">
            <img src={nasaSpaceApps} alt="NASA Space Apps Challenge Logo" />
          </div>
          <div className="certification-details">
            <h3>NASA Space Apps Challenge | 1st Place</h3>
            <p>Dr. Keith Gaddis</p>
            <p className="certification-date">Issued: October 2024</p>
            <a href="https://www.linkedin.com/in/omid-latifi-2bb380215/overlay/1728314523591/single-media-viewer/?profileId=ACoAADZWfa8BaZIuFij7r7KA8hxtEQZMyyHWoJo" className="certification-link">View Certificate</a>
          </div>
        </div>

    <div className="certification-item">
          <div className="certification-logo">
            <img src={RiseLogo} alt="RISE & Innov8 Logo" />
          </div>
          <div className="certification-details">
            <h3>RISE & INNOV8 Symposium | 1st Place</h3>
            <p>Dr. Mira Ray</p>
            <p className="certification-date">Issued: April 2024</p>
            <a href="https://www.linkedin.com/in/omid-latifi-2bb380215/overlay/1712695104654/single-media-viewer/?profileId=ACoAADZWfa8BaZIuFij7r7KA8hxtEQZMyyHWoJo" className="certification-link">View Certificate</a>
          </div>
        </div>

      <div className="certification-item">
          <div className="certification-logo">
            <img src={AIHackLogo} alt="Microsoft Logo" />
          </div>
          <div className="certification-details">
            <h3>AI Hackathon 2.0 | Top 3 Finalist</h3>
            <p>Georgian College, Henry Bernick Entrepreneurship Center</p>
            <p className="certification-date">Issued: February 2024</p>
            <a href="https://www.linkedin.com/in/omid-latifi-2bb380215/details/certifications/1715604728572/single-media-viewer/?profileId=ACoAADZWfa8BaZIuFij7r7KA8hxtEQZMyyHWoJo" className="certification-link">View Certificate</a>
          </div>
        </div>  

        </div>
    </section>
       
        <section id="education">
  <h2>Education</h2>
  <div className="education-container">
    <div className="education-item">
    <div className="logo-container">
            <LULogoModel />
          </div>
      <div className="education-details">
        <div className="education-info">
          <div className="info-row">
            <div className="info-left">
              <h3>Lakehead University</h3>
              <p className="degree">Bachelor of Science in Computer Science (Honors)</p>
            </div>
            <div className="info-right">
              <p className="duration">September 2022 - April 2026</p>
              <p className="location">Orillia, Ontario, Canada</p>
            </div>
          </div>
          <div className="content-row">
            <ul className="achievements">
              <li>Honors Computer Science Program</li>
              <li>President's List of Exceptional Students Award Recipient</li>
              <li>President of the Computer Science Club</li>
              <li>2024 AI Hackathon Winner</li>
              <li>2024 RISE (Research, Innovation, Scholarship, and Entrepreneurship) & INNOV8 Symposium Winner </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
        <h2>Contact</h2>
        <section id="contact">
          <div className="contact-info">
            <p>
              <i className="fas fa-envelope" />{" "}
              <a href="omidlatifi2002@gmail.com">omidlatifi2002@gmail.com</a>
            </p>
            <p>
              <i className="fab fa-github" />{" "}
              <a href="https://github.com/OmidLatifi123" target="_blank" rel="noopener noreferrer">
                github.com/OmidLatifi123
              </a>
            </p>
            <p>
              <i className="fab fa-linkedin" />{" "}
              <a
                href="https://www.linkedin.com/in/omid-latifi-2bb380215/"
                target="_blank"
                rel="noopener noreferrer"
              >
                linkedin.com/in/omid-latifi
              </a>
            </p>
          </div>
        </section>
        <footer>
  <p>© {new Date().getFullYear()} Omid Latifi. Connect with me on:     
    <a href="https://github.com/OmidLatifi123" target="_blank" rel="noopener noreferrer">
     <p></p>GitHub
  </a>
    {' | '}
  <a href="https://www.linkedin.com/in/omid-latifi-2bb380215/" target="_blank" rel="Omid Latifi LinkedIn">
    LinkedIn
  </a>
  </p>
</footer>
      </div>
    </>
  );
}